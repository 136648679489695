import { combineReducers, configureStore } from "@reduxjs/toolkit";
import apiReducer from "../slices/slice";
import { api } from "../api/api";

export const rootReducer = combineReducers({
  api: apiReducer,
  [api.reducerPath]: api.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export default store;

import paginationStyles from "./pagination.module.css";

function Pagination({
  items,
  activeIndex,
  onClick,
}: {
  items: String[];
  activeIndex: number;
  onClick: (index: number) => void;
}) {
  return (
    <ul className={paginationStyles.pagination}>
      {items.map((item, index) => (
        <li
          key={index}
          className={`${paginationStyles.page} ${
            index === activeIndex && paginationStyles.page_active
          }`}
          onClick={() => onClick(index)}
        />
      ))}
    </ul>
  );
}

export default Pagination;

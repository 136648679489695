import styles from "./typingNote.module.css";

function TypingNote({ name }: { name: string }) {
  return (
    <div className={styles.note}>
      <span>{name}</span>
      <span>מקליד/ה</span>
      <span className={styles.loader} />
    </div>
  );
}

export default TypingNote;

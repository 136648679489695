import { IUser } from "./types";
import venchy from "../images/avatar_venchy.png";
import { TQuestion } from "../services/types";

export const getTimeFromTimestamp = (date: string | Date) => {
  if (typeof date !== "string") {
    date = formatDateTimeInIsrael(date);
  }
  const timestamp = date.split("T");
  return `${timestamp[1].slice(0, 5)}`;
};

export const venchyData: IUser = {
  id: "venchy",
  name: "וונצ’י",
  avatar: venchy,
};

export const israeliPhoneRegExp =
  /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;

export const BASE_URL = "https://api.bpreven.com/";
export const SOCKET_SERVER_URL = "wss://api.bpreven.com/ChatApp/ws";
export const prevencherId = "prevencherId";
export const mobileWidth = "(max-width: 600px)";

export function getPrintDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export const termsArrayGeneral = [
  {
    id: 1,
    title: "הגדרה",
    text: 'חברת ביפריוון בע\'\'מ Bpreven Ltd ("המפעילה" או "אנו") מפעילה מערכת המאפשרת קבלת סיוע נפשי ראשוני, טיפול נפשי אונליין, מערכת ניהול טיפול וקליניקה למטפלים (להלן "המערכת").',
  },
  {
    id: 2,
    title: "שירותים",
    text: "עמותת מקשיבים (להלן ''נותנת שירות סיוע נפשי ראשוני'') מפעילה ביחד עם ביפריוון את מחלקת ענ''ד (עזרה נפשית דחופה) הנותנת מענה בקו חם ובמשאבים נוספים זמינים, לאנשים שזקוקים לאוזן קשבת.",
  },
  {
    id: 3,
    title: "אחריות",
    text: "החברה (להלן ''המפעילה'') הינה חברה טכנולוגית המעניקה שירותי צד ג. נותני השירות והמענה הנפשי הינם אנשי מקצוע, מדריכים בלימודי תארים מתקדמים בטיפול המקבלים הכשרה ייעודית. והאחריות על השימוש הינה על המשתמש בלבד.",
  },
  {
    id: 4,
    title: "תנאי שימוש",
    text: 'תנאי השימוש המוצגים להלן ("תנאי השימוש" או "ההסכם") מסדירים את היחסים בין המפעילה לבין המשתמש (כהגדרתו להלן) ביחס לשימוש בפלטפורמה כהגדרתה להלן).',
  },
  {
    id: 5,
    title: "הסכמים נוספים",
    text: "אין בתנאי השימוש כדי לגרוע או לשנות מכל הסכם אחר בין המשתמש לבין המפעיל. בכל מקרה של סתירה בין תנאי השימוש לבין מסמכים פרטניים אחרים, יגבר האמור במסמכים האחרים, אלא אם צוין במפורש אחרת. מבלי לגרוע מהאמור לעיל, בכל מקרה של סתירה או אי התאמה בין תנאי השימוש לבין תנאים או הוראות המפורסמים בכל אמצעי פרסום אחרים, יגברו הוראות תנאי השימוש.",
  },
  {
    id: 6,
    title: "הסכמה",
    text: "השימוש בפלטפורמה מהווה הסכמה וקבלת תנאי הסכם זה. משתמש שאינו מסכים לתנאי השימוש (חלקם או כולם) המנוסחים בהסכם זה, אינו רשאי לעשות שימוש כלשהו בפלטפורמה או בחלק ממנה.",
  },
  {
    id: 7,
    title: "הוראות צד ג",
    text: "מובהר בזאת כי תנאי הסכם זה פועלים במקביל ובכפוף לתנאי השימוש של צדדים שלישיים, ככל שיהיו כאלה, והם באים להוסיף עליהם.",
  },
  {
    id: 8,
    title: "נוסח הסכם",
    text: "ההסכם נכתב בלשון זכר מטעמי נוחות בלבד, והוא מכוון לשני המינים כאחד. כותרות הסעיפים הוכנסו לשם הנוחיות בלבד ואין להם, ולא יינתן להם, כל משקל בפירוש האמור בתנאי השימוש.",
  },
  {
    id: 9,
    title: "סליקת אשראי",
    text: "כל ההוראות בתנאי השימוש הנוגעות לסליקת כרטיסי אשראי יחולו על כל משתמש אשר רכש את שירותי תוסף הסליקה (כהגדרת מונח זה להלן), ומשתמש בפלטפורמה בין היתר לצורך סליקת עסקאות בכרטיס אשראי.",
  },
  {
    id: 10,
    title: "אחריות משתמש",
    text: "למען הסר ספק, השירות הינו פלטפורמת ניהול אשר האחריות לשימוש היעיל, החוקי והנכון בה היא על המשתמש בלבד.",
  },
  {
    id: 11,
    title: "בירור",
    text: "שימו לב כי התנאים הללו מכילים סעיף בירור ותנאי ויתור על תביעה ייצוגית, מה שישפיע על פתרון כל הפסקות בינך לבין מקום הדיבור.",
  },
  {
    id: 12,
    title: "קריאת תנאים",
    text: 'אנא קראו את התנאים הבאים בעיון ("התנאים"). התנאים הללו מסדירים ומחייבים את הגישה והשימוש שלך בפלטפורמת התקשורת של "ביפריוון בע"מ" ("המפעילה") בקשר עם השירותים המקוונים שאנו מציעים דרך האתר של "ביפריוון בע"מ" ("המפעילה").',
  },
  {
    id: 13,
    title: "הסכמה לתנאים",
    text: 'על ידי גישה או שימוש בשירות, אתה מסכים לכל התנאים וההגבלות המתוארים בתנאים אלו. אם אינך מסכים לכל התנאים וההגבלות הללו, אינך רשאי להשתמש בשירות. מונחים כגון "אתה", "שלך" ו"עצמך" מתייחסים למשתמש היחיד של השירות.',
  },
  {
    id: 14,
    title: "קשר עם ספקי האפליקציות",
    text: 'התנאים הללו מקשרים בינך לבין "ביפריוון בע"מ" (המפעילה"), ולא לספקי האפליקציות (כגון Apple App Store, Google Play Store וכיוצא בהם) שבהם האפליקציה זמינה. ספקי האפליקציות אינם שותפים לתנאים אלו ואינם מחויבים לספק תמיכה או תחזוקה באפליקציה. כל תביעה, אובדן, התחייבות, נזק, עלות או הוצאה הנובעת מכישלון באפליקציה תהיה באחריותו הבלעדית של "ביפריוון בע"מ" (המפעילה").',
  },
];

export const emergencyTermsArray = [
  {
    id: 15,
    title: "אזהרה",
    text: "אל תשתמשו בשירות זה לצורכי חירום רפואיים. אם נתקלתם במצב חירום רפואי, אנא צרו קשר מיד עם מספר 100 או 101 (בישראל) או במספר החירום הרלוונטי למקום מגוריכם.",
  },
  {
    id: 16,
    title: "מצב חירום",
    text: 'אם יש לכם כוונות או חשש מהתאבדות, ו/או אם אתם מתכננים לנקוט בפעולות שעשויות להזיק לכם או לאחרים, או אם יש לכם מצב חירום רפואי אחר, אנא צרו קשר מיד עם מוקד החירום הרלוונטי. המידע הרלוונטי למקרי חירום בבריאות הנפש זמינים באתר של "ביפריוון בע"מ". אנו ממליצים לכל המשתמשים להשתמש באתר זה כאשר הם נתקלים במצב המצריך סיוע ראשוני שאינו מסכן חיים.',
  },
  {
    id: 17,
    title: "העברת פרטים",
    text: "אני מודע כי כאשר יעלה החשד הסביר בצוות המקצועי של ביפריוון ו/או מקשיבים, על כי אני נמצא במצב המסכן חיים לעצמי ו/או לאחרים, החברה ו/או נותנת שירות ענ''ד יהיו רשאים להעביר את הפרטים שלי לגורמי החירום בלבד וזאת רק למטרה של הצלת חיים.",
  },
  {
    id: 18,
    title: "אמינות מידע",
    text: "אני מאשר כי היכולת שלי לגשת לשירות ולהשתמש בו מותנית באמיתות המידע שאני מספק לגבי גילי, מקומות מגוריי ופרטי הקשר שלי לשעת חירום, וכי הספקים אליהם אני נגש מסתמכים על אישור זה ליצירת איתי אינטראקציה ולספק את השירותים.",
  },
  {
    id: 19,
    title: "הסכמה",
    text: 'על ידי הרשמתי לשירותים שלכם, אני מסכים לתנאים אלה ומאשר את התנאים של הודעת נוהלי הפרטיות (עבור חברים בארה"ב בלבד) ואני מאשר שאתם עוסקים במידע שאני מספק לכם על פי מדיניות הפרטיות שלכם.',
  },
  {
    id: 20,
    title: "חובת דיווח",
    text: "במידה ומתנדבים בעמותת מקשיבים, ו/או הצוות המקצועי יעריכו כי קיים סיכון, אנו נפעל על פי חוק חובת דיווח.",
  },
];

export const additionalTermsArray = [
  {
    id: 21,
    title: "סכנה לחיי אדם",
    text: "אם נזהה סכנה ממשית לחיי אדם.",
  },
  {
    id: 22,
    title: "פגיעה עצמית או באחר",
    text: "במקרה של פגיעה עצמית באופן חמור או באדם אחר או ביצוע פשע.",
  },
  {
    id: 23,
    title: "פגיעה או התעללות",
    text: "פגיעה או התעללות בקטין/ה או חסר/ת ישע על ידי אדם האחראי עליו/ה.",
  },
];
export const registrationTermsArray = [
  {
    id: 24,
    title: "הרשמה לשירות",
    text: 'כדי לגשת לשירותים, עלי להירשם תחילה על ידי יצירת חשבון משתמש יחיד ("חשבון"), על פי המידע המפורט במדיניות הפרטיות. אני מסכים שלא אצור יותר מחשבון אחד או אצור חשבון עבור מישהו אחר מלבדי ללא קבלת אישור ראשון מהאדם השני. בתמורה לשימוש שלי בשירות, ואם רלוונטי, על מנת שספקים ישלחו לי הודעות, אני מסכים:',
  },
  {
    id: 25,
    title: "מידע אישי",
    text: "לספק מידע אמיתי, מדויק, עדכני ומלא על עצמי כפי שמתבקש ממני בטופס ההרשמה לחשבון שלכם (בהבנה שבמהלך פגישות טיפול אני עשוי להשתמש בכינוי); ולשמור ולעדכן באופן מיידי את פרטי החשבון שלי, בכל פעם שאני מתחבר, ככל האפשר כדי לשמור אותו נכון, מדויק, עדכני ומלא. אם אני מספק מידע שאינו נכון, מדויק, עדכני או שלם, או שאם יש לכם סיבה לחשוד שהמידע הזה שגוי, לא מדויק, לא עדכני או לא שלם, החברה שומרת לעצמה את הזכות להעביר את הטיפול שלי לספק אחר מחוץ לחברה או לקבוע צעדים אחרים כפי הצורך.",
  },
  {
    id: 26,
    title: "אישור גיל ומיקום",
    text: "לאשר כי אני (1) מעל גיל שמונה-עשרה (18) וכן (2) ממוקם פיזית או תושב במדינה או מדינה שבה אני מתגורר כעת בעת יצירת החשבון.",
  },
];

export const accountSecurityArray = [
  {
    id: 27,
    title: "שמירה על סודיות",
    text: "לשמור על סודיות סיסמת החשבון שלי ושם המשתמש שלי (אם קיימים), וכל מידע אבטחה נוסף הקשור לחשבוני בכל עת. החברה אינה אחראית לכל אובדן שייגרם לי כתוצאה מגישה של מישהו אחר ושימוש בחשבוני, בין אם בהידע שלי ובין שלא.",
  },
];
export const prohibitedContentArray = [
  {
    id: 29,
    title: "תוכן אסור",
    text: "מידע שאינו חוקי, לשון הרע, פוגעני, וולגרי, מגונה, גזעני, הונאה, טורף קטינים, מטריד, מאיים או שונא לכל אדם.",
  },
  {
    id: 30,
    title: "דואר אלקטרוני לא רצוי",
    text: "ופרסום או קידום של סחורות ושירותים.",
  },
  {
    id: 31,
    title: "תוכן שעלול לגרום נזק לצד שלישי",
    text: "תוכן שעלול לגרום נזק לצד שלישי",
  },
  {
    id: 32,
    title: "תוכן שעשוי להוות, לגרום או לעודד פעולה פלילית או להפר כל חוק החל",
    text: "תוכן שעשוי להוות, לגרום או לעודד פעולה פלילית או להפר כל חוק החל",
  },
  {
    id: 33,
    title:
      "מידע שמפר או מפר זכויות קניין רוחני של אחרים או זכויות פרטיות או פרסום של אחרים",
    text: "מידע שמפר או מפר זכויות קניין רוחני של אחרים או זכויות פרטיות או פרסום של אחרים",
  },
];

export const assistanceServiceArray = [
  {
    id: 34,
    title: "שירותי עזרה נפשית דחופה",
    text: "עמותת מקשיבים מפעילה את שירותי ענ''ד, ללא תשלום כחלק ממטרות פעילות העמותה.",
  },
  {
    id: 35,
    title: "אינך מחויב בתשלום",
    text: "ככל שתעשה שימוש בשירות קבלת עזרה נפשית דחופה באפליקציה, אינך מחויב בתשלום, ולא תתבקש לשלם עבור שירות זה.",
  },
  {
    id: 36,
    title: "התשלום עבור שירותים נוספים",
    text: "ככל ותהיה מעוניין לקבל שירותים נוספים מחברת ביפריוון (להלן ''המפעילה''), שירותים נוספים אשר ניתנים בתשלום, תקבל על כך הודעה מראש, כולל מחיר המנוי. וככל שהחברה תציע שירותים בתשלום סמלי, מדובר בפעילות החברה בלבד. ואין לכך קשר לעמותת מקשיבים (להלן נותנת שירותי עזרה נפשית דחופה) הפועלת ללא מטרת רווח. ",
  },
];
export const providersTermsArray = [
  {
    id: 37,
    title: "אזהרה",
    text: "ידוע לי כי נותני השירות (עמותת מקשיבים) הינם מתנדבים, ועל כן, העמותה תעשה כמיטב יכולתה לאפשר זמינות מלאה של 24 שעות ביממה. אך יחד עם זאת כיוון שמדובר במתנדבים ומשאבי זמן שעלולים להיות מוגבלים, איננו יכולים להתחייב על זמינות מלאה לכל אורך שעות היממה לכלל השיחות. ",
  },
  {
    id: 38,
    title: "מצב חירום",
    text: "תמיד אנו ממליצים במקרים דחופים וככל שאין מענה בשל עומסים ו/או כל סיבה אחרת, יש לפנות לגורמי החירום ו/או לחדר מיון הקרוב באזור מגוריכם על מנת לקבל סיוע נפשי ראשוני. ",
  },
  {
    id: 39,
    title: "העברת פרטים",
    text: "תמיד מומלץ לנקוט רמה גבוהה של זהירות בעת קבלת כל החלטה בריאותית נפשית או רפואית. לעולם אין להתעלם, להימנע או לעכב קבלת ייעוץ רפואי מהרופא הראשי או מספק שירותי בריאות מוסמך אחר, או שימוש או שילוב של ''ביפריוון בע''מ'' (להלן ''המפעילה'') עם פגישות פנים אל פנים מסורתיות, אך ורק בגלל מידע שראית באתר או באפליקציה או ייעוץ שאתה שהתקבל דרך הספק. ''ביפריוון בע''מ'' (להלן ''המפעילה'') תומכת בגישה הוליסטית לבריאות הנפש ולבחירות שיש לך, כדי ליצור את המסע הטיפולי הטוב ביותר המתאים לצרכים האישיים שלך. אם אתה מרגיש שהספק לא עונה על הציפיות שלך, אתה יכול להחליף לספק אחר בכל עת.",
  },
  {
    id: 40,
    title: "אמינות מידע",
    text: "מלבד ההדרכה והייעוץ שאתה מקבל ישירות מהספק שלך, אין לראות בגרפיקה, במקורות החינוכיים והמחקריים ומידע אגבי אחר באתר או בתוכן, ייעוץ רפואי. עליך תמיד לדבר עם איש מקצוע מוסמך בתחום הבריאות לצורך אבחון וטיפול, כולל מידע לגבי התרופות או הטיפול שעשויים להתאים לך. אף אחד מהתוכן אינו מייצג או מתחייב שכל תרופה או טיפול מסוימים בטוחים, מתאימים או יעילים עבורך. ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינה תומכת בבדיקות, תרופות, מוצרים או נהלים ספציפיים.",
  },
  {
    id: 41,
    title: "הסכמה",
    text: "''ביפריוון בע''מ'' (להלן ''המפעילה'') שומרת לעצמה את הזכות להגביל את היקף הפרקטיקה הקלינית הניתנת.\n",
  },
];

export const intellectualPropertyTermsArray = [
  {
    id: 42,
    title: "אזהרה",
    text: "כל התוכן הזמין בשירות או באמצעותו הוא רכושם של ''ביפריוון בע''מ'' (להלן ''המפעילה'') או של מעניקי הרישיונות שלו ומוגן על ידי זכויות יוצרים, סימן מסחרי, פטנט, סוד מסחרי וחוקי קניין רוחני אחרים. אתה מסכים לא לשכפל, לשנות, לשדר מחדש, להפיץ, להפיץ, למכור, לנצל, לפרסם, לשדר או להפיץ את התוכן שהתקבל דרך השירות לצד שלישי כלשהו. כל התוכנה והתיעוד הנלווה שזמינים להורדה מהשירות הם יצירה המוגנת בזכויות יוצרים של ''ביפריוון בע''מ'' (להלן ''המפעילה'') או מעניקי הרישיונות שלה. כל עותק שנוצר ממידע שהושג באמצעות השירות חייב לכלול את כל הודעות זכויות היוצרים החלות.\n",
  },
  {
    id: 43,
    title: "מצב חירום",
    text: "\"ביפריוון ו/או Bpreven\", \"קבלת עזרה 24 שעות בצאט וידאו ופורום\" ו\"הפעלות וידאו בשידור חי\" הם מונחים המוגנים בזכויות יוצרים. כל הזכויות שמורות. בכפוף לתנאי הסכם זה, ''ביפריוון בע''מ'' (להלן ''המפעילה'')  מעניקה לך בזאת רישיון מוגבל, ניתן לביטול, בלתי ניתן להעברה ולא בלעדי להשתמש בתוכנה, במתקני הרשת, בתוכן ובתיעוד בשירות ובמידה, ורק כדי במידה הדרושה כדי לגשת לשירות ולהשתמש בו.\n",
  },
];
export const doNotsTermsArray = [
  {
    id: 44,
    title: "אזהרה",
    text: "לשנות, לתרגם, לבצע הנדסה לאחור, לפרק, לפרק או ליצור עבודות נגזרות של השירות או לאפשר לצד שלישי, בין אם במישרין ובין אם בעקיפין (כולל, אך לא רק לשימוש ישיר או עקיף באשפים, סוכנים, בוטים או כלי עזר אחרים ), כדי לשנות, לתרגם, לבצע הנדסה לאחור, לפרק, לפרק או ליצור עבודות נגזרות של השירות; אוֹ",
  },
  {
    id: 45,
    title: "מצב חירום",
    text: "להעביר, להפיץ, למכור, להחכיר, להשכיר, לחשוף או לספק גישה לשירות לכל צד שלישי או להשתמש בשירות כדי לספק לשכת שירות, שיתוף זמן או שירותים אחרים לצדדים שלישיים.",
  },
];
export const disclaimerTermsArray = [
  {
    id: 46,
    title: "כתבי ויתור",
    text: 'הגישה לשירות והמידע הכלול בו מסופקים "כמות שהם" ו"כפי שהם זמינים". תחומי שיפוט מסוימים מספקים אחריות מסוימות, כמו האחריות המשתמעת של סחירות, התאמה למטרה מסוימת ואי-הפרה. במידה המותרת על פי חוק, אנו שוללים ומתנערים מכל אחריות, מפורשת או משתמעת.\n',
  },
  {
    id: 47,
    title: "כתבי ויתור",
    text: "מבלי להגביל את האמור לעיל, ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינה מתחייבת שהגישה לשירות תהיה ללא הפרעות או נטולת שגיאות, או שפגמים, אם בכלל, יתוקנו. ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינה מבטיחה הבטחות ספציפיות בקשר לשירותים, לרבות מהימנות, שלמות, אבטחה, איכות, דיוק, זמינות או תחולה עבורך של השירותים. אתה מסכים במפורש שהשימוש שלך בשירות וההסתמכות שלך על כל תוכן שלו הם באחריותך הבלעדית. בשימוש בשירותים, אתה מסכים במפורש שמלבד ההדרכה והייעוץ שאתה לא מספק מהספק שלך, אתה לא מספק את הספק שלך. ייעוץ בריאותי או רפואי אליך. אתה מסכים שאתה אחראי לשמירה על סודיות הסיסמה שלך וכל מידע אבטחה אחר הקשור לשימוש שלך ב-''ביפריוון בע''מ'' (להלן ''המפעילה''). אתה מסכים להודיע ​​ל-''ביפריוון בע''מ'' (להלן ''המפעילה'') באופן מיידי על כל שימוש לא מורשה בשם המשתמש או הסיסמה שלך.",
  },
  {
    id: 48,
    title: "כתבי ויתור",
    text: "אתה תהיה אחראי באופן בלעדי ומלא לכל נזק לשירות או לכל מערכת מחשב, כל אובדן נתונים, או כל שימוש או חשיפה לא נאותים של מידע על השירות שנגרם על ידי המשתמש שלך או על ידי המשתמש שלך או על ידי המשתמש שלך. ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינה יכולה ואינה נושאת באחריות כלשהי לאובדן, נזקים או התחייבויות הנובעות מכשל של תשתית טלקומוניקציה כלשהי, או האינטרנט, או לשימוש לא נכון במידע, מידע, תקלה כלשהי או הנחיות שניתן לגשת אליהן דרך ה שֵׁרוּת.\n",
  },
];
export const liabilityTermsArray = [
  {
    id: 49,
    title: "כתבי ויתור",
    text: "למעט מקרים אסורים, ''ביפריוון בע''מ'' (להלן ''המפעילה'') לא תישא באחריות לכל נזק עקיף, מיוחד, מקרי, תוצאתי או דוגמה לנזקים הנובעים מהשימוש שלך בשירותים או משימוש של צד שלישי כלשהו בשירות לטובתו.",
  },
  {
    id: 50,
    title: "כתבי ויתור",
    text: "בשום פנים ואופן לא ''ביפריוון בע''מ'' (להלן ''המפעילה''), כל רישיון או ספק של ''ביפריוון בע''מ'' (להלן ''המפעילה''), או כל צד שלישי שמקדם את השירות או מספק לך קישור לשירות יהיו אחראים בכל דרך לשימוש שלך בשימוש. שלא להיעזר בשירות שלך. מוגבל כל שגיאה או השמטה בכל תוכן, כל הפרה על ידי כל תוכן של זכויות הקניין הרוחני או זכויות אחרות של צדדים שלישיים, או לכל אובדן או נזק מכל סוג שהוא כתוצאה מכך שנגרמו כתוצאה מהארה.",
  },
  {
    id: 51,
    title: "כתבי ויתור",
    text: "תחומי שיפוט מסוימים אינם מאפשרים החרגה של אחריות מסוימות או החרגה או הגבלת אחריות לנזקים תוצאתיים או מקריים, ולכן ייתכן שההגבלות לעיל לא יחולו עליך. ",
  },
  {
    id: 52,
    title: "כתבי ויתור",
    text: "במידה המקסימלית המותרת על פי החוק החל, אתה מסכים להגן, לשפות ולהחזיק את ''ביפריוון בע''מ'' (להלן ''המפעילה''), חברות האם, והחברות הנלוות שלה, ואת נושאי המשרה, הדירקטורים, העובדים, הסוכנים, הנציגים, המבטחים והמחסות שלהן, בהתאמה, מכל תביעה שתתעורר. מתוך הפרתך של תנאים אלה וכל פעילות שלך הקשורה לשירותים או בקשר אליהם.",
  },
];
export const supportTermsArray = [
  {
    id: 53,
    title: "תנאי תמיכה",
    text: "''ביפריוון בע''מ'' (להלן ''המפעילה'') מספקת אפשרויות תמיכה מרובות כדי לסייע לך בניווט בפלטפורמת ''ביפריוון בע''מ'' (להלן ''המפעילה'') ובשירותים שאנו מספקים (\"תמיכה\"). ניתן להגיש כל בקשות לתמיכה ל-''ביפריוון בע''מ'' (להלן ''המפעילה'') באמצעות טופס אינטרנט מקוון הזמין באתר האינטרנט שלנו. אם נדרשת תמיכה נוספת, תהיה לך אפשרות לבצע מעקב עם ''ביפריוון בע''מ'' (להלן ''המפעילה'') בטלפון או בצ'אט תמיכה מקוון. בהקשר לבקשתך לתמיכה, עליך לשתף פעולה באופן סביר עם ''ביפריוון בע''מ'' (להלן ''המפעילה'') כדי להקל על כל פתרון בעיות הנדרש.\n",
  },
  {
    id: 54,
    title: "תנאי תמיכה",
    text: "עליך להגביל את המידע האישי המשותף בתוך מערכות אלו למינימום. לידיעתך, אנשים המספקים שירותי תמיכה יכולים לגשת לכל מידע המשותף בבקשות לתמיכה, לרבות אלה באמצעות מערכת כרטוס תמיכה של צד שלישי. לכן, אל תכלול פרטי כרטיס אשראי או מידע רגיש אחר בדוא\"ל או בהתכתבות המקוונת שלך עם ''ביפריוון בע''מ'' (להלן ''המפעילה''). אנא שימו לב כי כל בקשות לתמיכה לא צריכות לכלול בקשות לייעוץ רפואי. בשום פנים ואופן אין לראות בהערות שהתקבלו בתגובת תמיכה כטיפול רפואי. אם אתה נמצא במצב של סכנת חיים - אל תשתמש באתר זה. התקשר למספר 100 או השתמש במשאבים אלה כדי לקבל עזרה מיידית.\n",
  },
  {
    id: 55,
    title: "תנאי תמיכה",
    text: "לידיעתך, על ידי הרשמה, גישה ו/או שימוש בתמיכה, אתה מסכים ומסכים להיות כפוף לתנאי השימוש של ''ביפריוון בע''מ'' (להלן ''המפעילה'') ומסכים ש-''ביפריוון בע''מ'' (להלן ''המפעילה'') יאחסן מידע הנוגע לכל הודעות בקשת תמיכה באמצעות מערכת כרטוס תמיכה של צד שלישי. עוד שים לב שכאשר אתה שולח בקשה לתמיכה, ''ביפריוון בע''מ'' (להלן ''המפעילה'') עשויה לגשת לחשבון המשתמש שלך.\n",
  },
];

export function getGreeting(): string {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "בוקר טוב,";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "צהריים טובים,";
  } else if (currentHour >= 17 && currentHour < 22) {
    return "ערב טוב,";
  } else {
    return "לילה טוב,";
  }
}

export const formatDateTime = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};

export const formatDateTimeInIsrael = (dateInput: Date | string): string => {
  // Convert input to a Date object if it's a string
  const date = new Date(dateInput);

  // Use Intl.DateTimeFormat to get the date in Israel time
  const israelTime = new Intl.DateTimeFormat("en-GB", {
    timeZone: "Asia/Jerusalem", // The IANA time zone identifier for Israel
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour format
  }).format(date);

  // Extract the components from the formatted Israel time
  const [formattedDate, formattedTime] = israelTime.split(", ");
  const [day, month, year] = formattedDate.split("/");
  const [hours, minutes] = formattedTime.split(":");

  // Format to desired output (YYYY-MM-DDTHH:mm:ssZ)
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// export function getIsraelTime(dateInput: Date | string) {
//   // Convert input to a Date object if it's a string
//   const date = new Date(dateInput);
//
//   // Use Intl.DateTimeFormat to convert the date to Israel Time (Asia/Jerusalem)
//   const israelTime = new Intl.DateTimeFormat("il-IL", {
//     timeZone: "Asia/Jerusalem", // The IANA time zone identifier for Israel
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     hour12: false, // Use 24-hour format
//   }).format(date);
//
//   return israelTime;
// }

export function findNextUnansweredQuestion(
  array: TQuestion[],
  nextQuestionId: number
): TQuestion | null {
  // Find the object with the current nextQuestionId
  let currentQuestion = array.find(
    (question) => question.Id === nextQuestionId
  );
  // If the object is found
  if (currentQuestion) {
    // If the answer is null, return the current question
    if (!currentQuestion.Answer) {
      return currentQuestion;
    } else {
      // If the answer is not null, recursively find the next question using its NextQuestionId
      return findNextUnansweredQuestion(array, currentQuestion.NextQuestionId);
    }
  }

  // If the question is not found, return null
  return null;
}

export function parseTypingString(
  typingString: string
): { userId: string; isTyping: boolean } | null {
  // Use a regular expression to capture userId and isTyping from the string
  const regex = /TYPING uid(\d+) isTyping(true|false)/;
  const match = typingString.match(regex);

  // If the string matches the expected format, extract the userId and isTyping values
  if (match) {
    const userId = match[1]; // Extract userId
    const isTyping = match[2] === "true"; // Convert 'true'/'false' to boolean
    return { userId: userId, isTyping: isTyping };
  } else {
    // Return null if the string does not match the expected format
    return null;
  }
}

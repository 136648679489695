import textSliderStyles from "./slider.module.css";
import {
  Dispatch,
  SetStateAction,
  TouchEvent,
  useCallback,
  useState,
} from "react";

function Slider({
  items,
  className,
  activeIndex,
  setActiveIndex,
}: {
  items: string[];
  className: string;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
}) {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex: number) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);
  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);
  const handleTouchStart = (event: TouchEvent) => {
    if (touchStartX) return;
    setTouchStartX(event.touches[0].clientX);
  };
  const handleTouchEnd = (event: TouchEvent) => {
    if (touchStartX === null) return;
    const currentX = event.changedTouches[0]?.clientX;
    const diffX = touchStartX - currentX;

    if (diffX > 50) {
      handlePrev();
    } else if (diffX < -50) {
      handleNext();
    }
    setTouchStartX(null);
  };

  return (
    <div
      className={textSliderStyles.carouselWrapper}
      onTouchStart={handleTouchStart}
      onTouchEnd={(e) => handleTouchEnd(e)}
    >
      <div className={textSliderStyles.carouselItems}>
        {items.map((item, index: number) => {
          return (
            <div
              key={index}
              className={`${textSliderStyles.carouselItem} `}
              style={{
                transform: `translateX(${activeIndex * 100}%)`,
              }}
            >
              <p className={className}>{item}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Slider;

import styles from "./chatPage.module.css";
import { SOCKET_SERVER_URL } from "../../utils/utils";
import ChatTherapist from "../../components/chatTherapist/chatTherapist";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { useEffect, useReducer } from "react";
import { WSActions, wsReducer } from "../../utils/ws.reducer";
import { useAppSelector } from "../../services/hooks";
import { appointmentSelector } from "../../services/selectors";
import { useLazyGetAppointmentByIdQuery } from "../../services/api/api";
import { useParams } from "react-router-dom";
import useConditionalWebSocket, {
  TWSConfig,
} from "../../hooks/useConditionalWebSocket";

function ChatPage() {
  const { appointmentId, usersId } = useParams();
  const [chatState, dispatch] = useReducer(wsReducer, {
    userId: usersId as string,
    connected: false,
    users: [],
    messages: [],
    active: false,
    therapistClosedConnection: false,
    isTyping: false,
  });
  const appointment = useAppSelector(appointmentSelector);
  const [getAppointmentById] = useLazyGetAppointmentByIdQuery();
  const isTherapistOnline: boolean =
    chatState.active &&
    !!chatState.users.find(
      (u) => u === `${appointment?.TherapistsId}${appointment?.Id}`
    );
  useEffect(() => {
    if (appointmentId) {
      getAppointmentById(appointmentId);
    }
  }, [
    appointmentId,
    isTherapistOnline,
    getAppointmentById,
    chatState.therapistClosedConnection,
  ]);
  //check if appointment was closed by therapist to show not to the user
  useEffect(() => {
    if (appointment && !appointment.Active) {
      dispatch({
        type: WSActions.CHAT,
        payload: {
          Content: "CLOSE",
          Type: "",
          Sender: "",
          RoomId: "",
          Users: [],
        },
      });
    }
  }, [appointment, dispatch]);
  useEffect(() => {
    if (
      !chatState.therapistClosedConnection &&
      appointment &&
      appointment.Active &&
      appointment.UsersId.toString() === usersId
    ) {
      dispatch({ type: "SET_ACTIVE" });
    } else {
      const prevMessagesString = appointment?.AppointmentReport;
      if (prevMessagesString && appointment.UsersId.toString() === usersId) {
        prevMessagesString &&
          dispatch({
            type: "STORED_MESSAGES",
            payload: {
              Type: "STORED_MESSAGES",
              Sender: usersId,
              Content: prevMessagesString,
              RoomId: appointment?.Id.toString(),
              Users: [],
            },
          });
      }
    }
  }, [appointment, dispatch, usersId, chatState.therapistClosedConnection]);

  //prevent zoom for the page on mobile devices
  useEffect(() => {
    // Create the meta tag
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";

    // Append the meta tag to the head
    document.head.appendChild(metaTag);

    // Cleanup function to remove the meta tag when the component unmounts
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  const wsConfig: TWSConfig = {
    url: `${SOCKET_SERVER_URL}?username=${usersId}&roomId=${appointmentId}`,
    shouldConnect: chatState.active,
    appointmentId,
    dispatch,
    usersId,
  };
  const { sendMessage } = useConditionalWebSocket(wsConfig);

  if (!appointment || !appointmentId) return <p>פגישה זו אינה פעילה יותר</p>;
  if (!usersId) return <p>משתצש לא קיים</p>;

  return (
    <section className={`${styles.chatPage} ${styles.nooverflow}`}>
      <ChatTherapist
        therapist={{ name: appointment.TherapistFullName }}
        messages={chatState.messages}
        userId={usersId}
        room={appointmentId}
        sendJsonMessage={sendMessage}
        chatState={chatState}
        isTherapistOnline={isTherapistOnline}
      />
      <ChatSideBar
        therapist={{ name: appointment.TherapistFullName }}
        isTherapistOnline={isTherapistOnline}
      />
    </section>
  );
}

export default ChatPage;

import styles from "./termsPopup.module.css";
import {
  accountSecurityArray,
  additionalTermsArray,
  assistanceServiceArray,
  disclaimerTermsArray,
  doNotsTermsArray,
  emergencyTermsArray,
  intellectualPropertyTermsArray,
  liabilityTermsArray,
  prohibitedContentArray,
  providersTermsArray,
  registrationTermsArray,
  supportTermsArray,
  termsArrayGeneral,
} from "../../utils/utils";
import logo from "../../images/logo_terms.jpg";

function TermsPopup({ onClose }: { onClose: () => void }) {
  return (
    <section className={styles.terms}>
      <img src={logo} alt={"לוגו"} className={styles.terms__logo} />
      <h2 className={`${styles.terms__title} ${styles.terms__title_main}`}>
        תנאי שימוש: חברת ביפריוון בע''מ
      </h2>
      <h3 className={styles.terms__subtitle}>כללי:</h3>
      <ul className={styles.terms__list}>
        {termsArrayGeneral.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>מידע ליצירת קשר לשעת חירום:</h3>
      <ul className={styles.terms__list}>
        {emergencyTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>
        במקרים הבאים, על פי חוק, לא נוכל להבטיח סודיות:
      </h3>
      <ul className={styles.terms__list}>
        {additionalTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>זכאות לחשבון ורישום:</h3>
      <ul className={styles.terms__list}>
        {registrationTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>החשבון שלי:</h3>
      <ul className={styles.terms__list}>
        {accountSecurityArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>שימוש סביר:</h3>
      <ul className={styles.terms__list}>
        <li className={styles.terms__li}>
          אתה מסכים לא לגשת או להשתמש בשירותים בדרך לא חוקית או למטרה לא חוקית
          או לא לגיטימית או בכל דרך שהיא הסותרת תנאים אלה. אין לפרסם, להשתמש,
          לאחסן או לשדר כל אחד מהדברים הבאים:
        </li>
        <h3 className={styles.terms__subtitle}>הודעה ומידע בשם בדוי:</h3>
        <h4 className={styles.terms__subtitle_thin}>אין לפרסם או להעביר:</h4>
        <ul className={styles.terms__list}>
          {prohibitedContentArray.map((item) => (
            <li className={styles.terms__li}>{item.text}</li>
          ))}
        </ul>
        <h3 className={styles.terms__subtitle}>
          אין לנסות לשבש את פעולת השירות בכל דרך, לרבות באמצעות:
        </h3>
        <li className={styles.terms__li}>
          וירוסים, סוסים טרויאניים, תולעים, פצצות זמן, תוכנות ריגול, תוכנות
          פרסום, התקפות מניעת שירות, הצפה או ספאם.
        </li>
        <li className={styles.terms__li}>
          השימוש בשירות בדרכים העלולות להזיק, להשבית או לפגוע בשירות. ניסיון
          לגשת לכל חשבונות משתמש או מערכות מחשב או רשתות באמצעות פריצה, כריה של
          סיסמאות או כל אמצעי אחר. שימוש ברובוט, מגרד, קוד זדוני או אמצעי אחר
          לגישה לשירות למטרה שהיא.{" "}
        </li>
        <li className={styles.terms__li}>
          החברה או שותפיה אינם מחויבים להגיב להודעות שנשלחו בשירות. המשתמש אחראי
          הבלעדי למידע או לחומר שהוא מפרסם בשירות.
        </li>
        <li className={styles.terms__li}>
          במידה ותוריד את האפליקציה, אתה מסכים כי לעיתים קרובות, התוכנה עשויה
          לדרוש ממך להוריד שדרוגים, עדכונים ותכונות נוספות שתוכנו מפרסמת על מנת
          לשפר, לשדרג ולפתח את התוכנה, השירות או האפליקציה.
        </li>
      </ul>
      <h3 className={styles.terms__subtitle}>תשלום:</h3>
      <ul className={styles.terms__list}>
        {assistanceServiceArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>ספקים:</h3>
      <ul className={styles.terms__list}>
        {providersTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>קניין רוחני:</h3>
      <ul className={styles.terms__list}>
        {intellectualPropertyTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>
        הרישיון שניתן כאן אינו מתיר לך, ואתה מסכים שלא:
      </h3>
      <ul className={styles.terms__list}>
        {doNotsTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>כתבי ויתור:</h3>
      <ul className={styles.terms__list}>
        {disclaimerTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>מגבלות אחריות ; שיפוי</h3>
      <ul className={styles.terms__list}>
        {liabilityTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>היפר-קישורים:</h3>
      <ul className={styles.terms__list}>
        <li className={styles.terms__li}>
          כל קישור (לרבות היפר-קישור, כפתור או מכשיר הפניה מכל סוג שהוא) המשמש
          בשירות מסופק לשימושך ולנוחותך. הופעתו של קישור אינה מהווה אישור, המלצה
          או הסמכה על ידי ''ביפריוון בע''מ'' (להלן ''המפעילה''), ואין לפרש את
          נוכחותו של קישור בשום אופן כהצעה לכך שלכל אתר אינטרנט של צד שלישי יש
          קשר כלשהו ל-''ביפריוון בע''מ'' (להלן ''המפעילה''). ''ביפריוון בע''מ''
          (להלן ''המפעילה'') אינה מאשרת את התוכן באתרי צד שלישי כלשהם.
          ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינה אחראית לתוכן של אתרי צד
          שלישי מקושרים או פרסומות של צד שלישי ואינה מציגה כל מצג לגבי תוכנם או
          דיוקם. ''ביפריוון בע''מ'' (להלן ''המפעילה'') אינו מקשר ביודעין לאתרים
          שעלולים להפר סימנים מסחריים, סימני שירות, זכויות יוצרים או פטנטים
          תקפים וקיימים. השימוש שלך באתרי צד שלישי הוא על אחריותך הבלעדית ובכפוף
          לתנאי השימוש באתרים כאלה.
        </li>
      </ul>
      <h3 className={styles.terms__subtitle}>סיום:</h3>
      <ul className={styles.terms__list}>
        <li className={styles.terms__li}>
          ל-''ביפריוון בע''מ'' (להלן ''המפעילה'') יש את הזכות (אך לא את החובה)
          לסרב לספק גישה לשירות לכל אדם, סוכנות או ארגון בגין כל שימוש לא חוקי
          או לא תקין בשירות, אי תשלום או שימוש לרעה מהותי אחר בשירותים.
          ''ביפריוון בע''מ'' (להלן ''המפעילה'') שומרת לעצמה את הזכות לשנות,
          להשעות או להפסיק את השירות כולו או חלק ממנו, באופן זמני או קבוע, ללא
          הודעה מוקדמת.
        </li>
      </ul>
      <h3 className={styles.terms__subtitle}>תנאי תמיכה:</h3>
      <ul className={styles.terms__list}>
        {supportTermsArray.map((i) => (
          <li key={i.id} className={styles.terms__li}>
            {i.text}
          </li>
        ))}
      </ul>
      <h3 className={styles.terms__subtitle}>חוק חל ומחלוקות:</h3>
      <h4 className={styles.terms__subtitle}>משתמשים בישראל:</h4>
      <p className={styles.terms__li}>
        התנאים וכל מחלוקת או תביעה (כולל מחלוקות או תביעות שאינן חוזיות) הנובעות
        או בקשר עם התנאים או הנושא או היווצרותם או השימוש בשירותים יהיו כפופים
        לחוקי ישראל. וכל מחלוקת תדון בבית משפט בירושלים או בתל אביב.{" "}
      </p>
      <h3 className={styles.terms__subtitle}>כללי:</h3>
      <p className={styles.terms__li}>
        כישלון של ''ביפריוון בע''מ'' (להלן ''המפעילה'') לאכוף כל זכות או הוראה
        של תנאים אלה לא ייחשב כוויתור על זכויות אלה. אם הוראה כלשהי בתנאים אלה
        תיקבע כפסולה או בלתי ניתנת לאכיפה על ידי בית משפט, שאר ההוראות של תנאים
        אלה יישארו בתוקף. הודעות הנדרשות למסור לך על ידי ''ביפריוון בע''מ''
        (להלן ''המפעילה'') עשויות להימסר בדואר אלקטרוני לכתובת שסופקה במהלך
        יצירת חשבונך. תנאים אלה מהווים את כל ההסכם בין ''ביפריוון בע''מ'' (להלן
        ''המפעילה'') לעצמך בנוגע לשירות ומחליפים ומחליפים כל הסכמים קודמים שיש
        לך ול-''ביפריוון בע''מ'' (להלן ''המפעילה'') לגבי השירות. ''ביפריוון
        בע''מ'' (להלן ''המפעילה'') עשויה לשנות תנאים אלה מעת לעת ללא הודעה אליך,
        למעט שינויים מהותיים. על ידי המשך גישה או שימוש בשירות שלנו לאחר
        שהתיקונים הללו נכנסים לתוקף, אתה מסכים להיות כפוף לתנאים המתוקנים. אנו
        ממליצים לך לקרוא את התנאים מעת לעת.
      </p>
      <button className={"button"} onClick={onClose}>
        לסגור
      </button>
    </section>
  );
}

export default TermsPopup;

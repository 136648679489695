import chatSidebarStyles from "./chatSidebar.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";

function ChatSideBar({
  therapist = { name: "@Name" },
  isTherapistOnline,
}: {
  therapist: { name: string };
  isTherapistOnline: boolean;
}) {
  return (
    <section className={`${chatSidebarStyles.sidebar}`}>
      <ul
        className={`${chatSidebarStyles.sidebar__therapists} ${chatSidebarStyles.scrollable} scroll`}
      >
        <li className={`${chatSidebarStyles.sidebar__li} `}>
          <div
            className={`${chatSidebarStyles.sidebar__online} ${
              isTherapistOnline && chatSidebarStyles.sidebar__online_active
            }
              `}
          >
            <img
              className={chatSidebarStyles.sidebar__avatar}
              src={avatarPlaceholder}
              alt={therapist.name}
            />
          </div>
          <div className={chatSidebarStyles.sidebar__therapist}>
            <p className={chatSidebarStyles.sidebar__name}>{therapist.name}</p>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default ChatSideBar;

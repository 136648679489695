import questionnaireStyles from "./questionnaire.module.css";
import { useInView } from "react-intersection-observer";
import questionnaireDoctor from "../../images/questionnaire_doctor.png";

function Questionnaire({ link, userName }: { link: string; userName: string }) {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  return (
    <>
      <a href={link} ref={ref} className={questionnaireStyles.questionnaire}>
        <img
          src={questionnaireDoctor}
          alt="bpreven doctor"
          className={questionnaireStyles.questionnaire__doctor}
        />

        <p className={questionnaireStyles.questionnaire__answer}>
          {/*{getStartQuestion()}*/}
          שלום {userName},<span>אני תמיד כאן בשבילך</span>
        </p>
      </a>
      {!inView && (
        <div className={`${questionnaireStyles.questionnaire_small}`}>
          <img
            src={questionnaireDoctor}
            alt="bpreven doctor"
            className={questionnaireStyles.questionnaire__doctor}
          />
          <p className={questionnaireStyles.questionnaire__answer_small}>
            אני תמיד כן בשבילך
          </p>
        </div>
      )}
    </>
  );
}

export default Questionnaire;

import myDayStyles from "./myDay.module.css";
import venchy from "../../images/questionnaire_doctor.png";
import { getGreeting, getPrintDate } from "../../utils/utils";
import Pagination from "../pagination/pagination";
import { useState } from "react";
import Slider from "../slider/slider";
import { useAppSelector } from "../../services/hooks";
import { updatesSelector } from "../../services/selectors";

function MyDay() {
  const updates = useAppSelector(updatesSelector);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={myDayStyles.myDay}>
      <h3 className={myDayStyles.myDay__title}>{getGreeting()}</h3>
      <h3 className={myDayStyles.myDay__title}>כמה דברים במיוחד בשבילך:</h3>
      <div className={myDayStyles.myDay__section}>
        <h4 className={myDayStyles.myDay__sectionTitle}>עדכונים והמלצות</h4>
        <div className={myDayStyles.notifications}>
          <div className={myDayStyles.notifications__venchy}>
            <img
              src={venchy}
              className={myDayStyles.notifications__img}
              alt={"תמונה של וונצ’י"}
            />
            <div>
              <p className={myDayStyles.notifications__name}>וונצ’י</p>
              <p className={myDayStyles.notifications__date}>
                {getPrintDate(new Date())}
              </p>
            </div>
          </div>
          <Slider
            items={updates.map((u) => u.Desc)}
            className={myDayStyles.notifications__text}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <button
            className={myDayStyles.nextBtn}
            onClick={() =>
              setActiveIndex((prevIndex) =>
                prevIndex === updates.length - 1 ? 0 : prevIndex + 1
              )
            }
          />
        </div>
      </div>
      <Pagination
        items={updates.map((u) => u.Desc)}
        onClick={(index) => setActiveIndex(index)}
        activeIndex={activeIndex}
      />
    </div>
  );
}

export default MyDay;

import { IMessage } from "./types";
import { formatDateTime, parseTypingString } from "./utils";

export type TWSState = {
  userId: string | number;
  active: boolean;
  connected: boolean;
  users: string[];
  messages: IMessage[];
  therapistClosedConnection: boolean;
  isTyping: boolean;
};
export interface WSMessage {
  Type: string;
  Sender: string;
  Content: string;
  RoomId: string;
  Users: string[];
}
export type TWSAction = {
  type: string;
  payload?: WSMessage;
};
export enum WSActions {
  CONNECTION = "CONNECTION",
  CHAT = "CHAT",
  STORED_MESSAGES = "STORED_MESSAGES",
  SET_ACTIVE = "SET_ACTIVE",
}

export function wsReducer(state: TWSState, action: TWSAction) {
  const { type, payload } = action;
  // console.log(type, payload);
  switch (type) {
    case WSActions.CONNECTION:
      return {
        ...state,
        connected: true,
        users: payload?.Users || [],
      };
    case WSActions.SET_ACTIVE:
      return {
        ...state,
        active: true,
      };
    case WSActions.STORED_MESSAGES:
      if (payload?.Content) {
        return {
          ...state,
          messages: JSON.parse(payload.Content),
        };
      } else
        return {
          ...state,
        };
    case WSActions.CHAT:
      if (payload?.Content === "CLOSE") {
        return { ...state, active: false, therapistClosedConnection: true };
      }
      if (payload?.Content.includes("TYPING")) {
        // console.log(parseTypingString(payload?.Content));
        const userTypingState = parseTypingString(payload.Content);
        if (
          userTypingState &&
          userTypingState?.userId.toString() !== state.userId.toString()
        ) {
          return { ...state, isTyping: userTypingState.isTyping };
        } else return { ...state, isTyping: false };
      }

      // console.log(payload?.Content);
      const newMessage: IMessage = {
        messageId: payload?.Sender || "",
        id: payload?.Sender || "",
        date: formatDateTime(new Date()),
        message: payload?.Content || "",
      };

      //update sessionStorage
      if (payload) {
        const storedMessagesString = sessionStorage.getItem(payload.RoomId);
        const storedMessages = storedMessagesString
          ? JSON.parse(storedMessagesString)
          : [];
        sessionStorage.setItem(
          payload.RoomId,
          JSON.stringify([...storedMessages, newMessage])
        );
      }

      return {
        ...state,
        messages: [...state.messages, newMessage],
      };
    default:
      return state;
  }
}
